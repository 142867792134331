import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import Home from "../pages/Home";
import Recipes from "../pages/Recipes";
import Settings from "../pages/Settings";
import RecipeDetails from "../pages/RecipeDetails";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/recipes" element={<Recipes />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/recipe/:id" element={<RecipeDetails />} />
      {/* <Route path="/foods/:id" element={<FoodDetails />} /> */}
      
    </Routes>
  );
};

export default Routers;
