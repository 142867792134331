import React from "react";
import Navbar from "../Header";
import Routers from "../../routes/Routers";
import Footer from "../Footer";

const Layout = () => {
  return (
    <div>
      <Navbar />
      <div>
        <Routers />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
