import React from "react";
import { Col, Container, Row } from "reactstrap";

const Footer = () => {
  return (
    <section>
      <Container>
        <Row className="d-flex align-items-center justify-content-center">
          <Col lg="4" md="3" sm="4" xs="12" className="product-card mt-4">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nihil est
            corrupti deleniti eum eaque, earum eveniet tenetur quisquam
            temporibus ducimus.
          </Col>
          <Col lg="4" md="3" sm="4" xs="12" className="product-card mt-4">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint,
            laborum!
          </Col>
          <Col lg="4" md="3" sm="4" xs="12" className="product-card mt-4">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque error
            et fuga aliquam est, pariatur excepturi deleniti inventore quas
            similique ad. Officiis temporibus dolores incidunt.
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Footer;
