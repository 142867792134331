import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Form, FormGroup, Row } from "reactstrap";
import "../styles/recipes.css";
import axios from "axios";
import ProductsList from "../components/UI/ProductList";
import ProductCard from "../components/UI/ProductCard";

const Recipes = () => {
  const [searchValue, setSearchValue] = useState("");
  const [searchedProducts, setSearchedProducts] = useState([]);

  const getProducts = async (input) => {
    const api = await axios.get(
      `https://api.edamam.com/search?q=${input}&app_id=${process.env.REACT_APP_API_ID}&app_key=${process.env.REACT_APP_API_KEY}`
    );
    const data = api.data.hits;
    setSearchedProducts(data);
  };

  // useEffect(() => {
  //   getProducts();
  // }, []);

  const inputRef = useRef(null);
  async function handleClick(e) {
    e.preventDefault();
    // 👇️ update input value
    const input = (inputRef.current.value = searchValue);
    getProducts(input);

    // 👇️ access input value
    console.log(input);
  }

  return (
    <section className="mt-5 mb-5">
      <Container>
        <Row>
           
            <div className="form-section">
              <form>
                <input
                  ref={inputRef}
                  type="text"
                  placeholder="Search Recipe..."
                  className="search-recipe"
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <button onClick={handleClick} className="search-btn">Search</button>
              </form>
            </div>

          <ProductsList data={searchedProducts} />
        </Row>
      </Container>
    </section>
  );
};

export default Recipes;
