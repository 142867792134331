import React from "react";
import { Col } from "reactstrap";

import "./product-card.css";

const ProductCard = ({ item }) => {

  return (
    <Col lg="3" md="4" sm="6" xs="12" className="product-card">
      <div className="product__item">
        <div className="product__img">
          <img src={item.recipe.image} />
        </div>
        <div className="info">
          <div className="product__info">
            <h2 className="product__name">
              {/* <Link to={`/shop/${item.id}`}>{item.productName}</Link> */}
              <a href={item.recipe.url} target="_blank">
                {item.recipe.label}
              </a>
            </h2>
            <span>{item.recipe.cuisineType}</span>
          </div>
          <div className="product__card-bottom">
            <p>Ingredients</p>
            <ul>
              {item.recipe.ingredientLines.map((item) => (
                <li>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ProductCard;
