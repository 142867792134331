import React, { useEffect, useState } from "react";
import axios from "axios";
import { Col, Container, Row } from "reactstrap";
import "../styles/home.css";

import ProductsList from "../components/UI/ProductList";

const Home = () => {
  const [randomMeal, setRandomMeal] = useState([]);
  const [pizzaMeal, setPizzaMeal] = useState([]);
  const getPopular = async () => {
    const getRandomItem = (arr) => {
      const randomIndex = Math.floor(Math.random() * arr.length);
      const item = arr[randomIndex];
      return item;
    };
    const array = [
      "egg",
      "cheese",
      "pasta",
      "lasagna",
      "burger",
      "salad",
      "steak",
      "mushroom",
      "potato",
      'tomato',
      "apple",
      "peanut",
      "protein",
      "bar",
      "fruit",
      "ice cream",
    ];

    const result = getRandomItem(array);
    const api = await axios.get(
      `https://api.edamam.com/search?q=${result}&app_id=${process.env.REACT_APP_API_ID}&app_key=${process.env.REACT_APP_API_KEY}`
    );
    const data = api.data.hits;
    setRandomMeal(data);
  };

  const getPizza = async () => {
    const api = await axios.get(
      `https://api.edamam.com/search?q=pizza&app_id=${process.env.REACT_APP_API_ID}&app_key=${process.env.REACT_APP_API_KEY}`
    );
    const data = api.data.hits;
    setPizzaMeal(data);
  };


  useEffect(() => {
    getPopular();
    getPizza();
  }, []);

  return (
    <>
      <section className="random-section">
        <Container>
          <Row>
            <Col lg="12">
              <h4 className="text-headers">Random Meals</h4>
            </Col>
            <ProductsList data={randomMeal} />
          </Row>
        </Container>
      </section>
      {/* <section className="random-section">
        <Container>
          <Row>
            <Col lg="12">
              <h4 className="text-headers">Most wanted</h4>
            </Col>
            <ProductsList data={pizzaMeal} />
          </Row>
        </Container>
      </section> */}
    </>
  );
};

export default Home;
