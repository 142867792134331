import { Link, NavLink } from "react-router-dom";
import { useRef } from "react";
import { Container, Row, Col } from "reactstrap";
import "../styles/header.css";
const Navbar = () => {
  const nav_links = [
    {
      path: "/home",
      name: "Home",
    },
    {
      path: "/recipes",
      name: "Recipes",
    },
    {
      path: "/settings",
      name: "Settings",
    },
  ];

  const toggleRef = useRef(null);
  const menuRef = useRef(null);
  const blackRef = useRef(null);
  const toggle = () => {
    toggleRef.current.classList.toggle("active");
    menuRef.current.classList.toggle("active__menu");
    blackRef.current.classList.toggle("active__black");
  };
  const toggleMenu = () => {
  };

  return (
    <div className="navbar container">
      <Link to='/' className="logo">
        <span>Recipe</span>Hub
      </Link>
      <div className="black-everything" ref={blackRef} onClick={toggle}></div>
      <div className="nav-links" ref={menuRef} onClick={toggle}>
        {nav_links.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            // className={(navClass) => (navClass.isActive ? "active__menu" : "")}
          >
            {item.name}
          </NavLink>
        ))}
      </div>
      <div className="sidebar-btn" ref={toggleRef} onClick={toggle}>
        <div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
