import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";

const Settings = () => {
  const [settings, setSettings] = useState({
    "--background-color": "#fff",
    "--background-light": "#fff",
    "--primary-color": "rgb(255,0,86)",
    "--shadow-color": "rgba(0,0,0,0.2)",
    "--text-color": "#0A0A0A",
    "--text-light": "#575757",
    "--font-size": "16px",
    "--animation-speed": 1,
  });
  useEffect(() => {
    var settingsObject = window.localStorage.getItem("settings");
    let parsedSettings = JSON.parse(settingsObject);
    setSettings(parsedSettings ? parsedSettings : settings);
  }, []);

  useEffect(() => {
    const root = document.documentElement;
    for (let key in settings) {
      root.style.setProperty(key, settings[key]);
    }
  }, [settings]);

  let themeIndex = window.localStorage.getItem("theme");
  let primaryIndex = window.localStorage.getItem("primary");
  let fontIndex = window.localStorage.getItem("font");
  let animationIndex = window.localStorage.getItem("animation");

  const [theme, setTheme] = useState(
    themeIndex === null ? "light" : themeIndex
  );
  const [primaryColor, setPrimaryColor] = useState(
    !primaryIndex ? 0 : +primaryIndex
  );
  const [fontSize, setFontSize] = useState(!fontIndex ? 1 : +fontIndex);
  const [animationSpeed, setAnimationSpeed] = useState(
    !animationIndex ? 1 : +animationIndex
  );

  function changeTheme(i) {
    const _theme = { ...themes[i] };
    setTheme(i === 0 ? "light" : "dark");
    // update settings
    let _settings = { ...settings };
    for (let key in _theme) {
      _settings[key] = _theme[key];
    }
    setSettings(_settings);
    window.localStorage.setItem("settings", JSON.stringify(_settings));
    window.localStorage.setItem("theme", i === 0 ? "light" : "dark");
  }

  function changePrimary(i) {
    const _primary = primaryColors[i];
    let _settings = { ...settings };
    _settings["--primary-color"] = _primary;
    setSettings(_settings);
    setPrimaryColor(i);
    window.localStorage.setItem("settings", JSON.stringify(_settings));
    window.localStorage.setItem("primary", i);
  }
  function changeFontSize(i) {
    const _font = { ...fontSizes[i] };
    let _settings = { ...settings };
    _settings["--font-size"] = _font.value;
    console.log(_settings);
    setSettings(_settings);
    setFontSize(i);

    window.localStorage.setItem("settings", JSON.stringify(_settings));
    window.localStorage.setItem("font", i);
  }

  function changeSpeed(i) {
    const _speed = { ...animationSpeeds[i] };
    let _settings = { ...settings };
    _settings["--animation-speed"] = _speed.value;
    setSettings(_settings);
    setAnimationSpeed(i);

    window.localStorage.setItem("settings", JSON.stringify(_settings));
    window.localStorage.setItem("animation", i);
  }

  const themes = [
    {
      "--background-color": "#fff",
      "--background-light": "#fff",
      "--shadow-color": "rgba(0,0,0,0.2)",
      "--text-color": "#0A0A0A",
      "--text-light": "#575757",
    },
    {
      "--background-color": "rgb(29, 29, 29)",
      "--background-light": "rgb(77, 77, 77)",
      "--shadow-color": "rgba(0,0,0,0.2)",
      "--text-color": "#ffffff",
      "--text-light": "#eceaea",
    },
  ];

  const primaryColors = [
    "rgb(255,0,86)",
    "rgb(33,150,243)",
    "rgb(255,193,7)",
    "rgb(0,200,83)",
    "rgb(156,39,176)",
  ];

  const fontSizes = [
    {
      title: "Small",
      value: "12px",
    },
    {
      title: "Medium",
      value: "16px",
    },
    {
      title: "Large",
      value: "20px",
    },
  ];
  const animationSpeeds = [
    {
      title: "Slow",
      value: "2",
    },
    {
      title: "Medium",
      value: "1",
    },
    {
      title: "Fast",
      value: ".5",
    },
  ];

  return (
    <section className="setting-section">
      <Container>
        <Row>
          <Col lg="12">
            <h2 className="mt-5 main-title">Preferred theme</h2>
          </Col>
          <Col lg="12">
            <div className="options-container">
              <div className="option light" onClick={() => changeTheme(0)}>
                {theme === "light" && (
                  <div className="check">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                )}
              </div>

              <div className="option dark" onClick={() => changeTheme(1)}>
                {theme === "dark" && (
                  <div className="check">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                )}
              </div>
            </div>
          </Col>

          {/* second */}

          <Col lg="12">
            <h2 className="mt-5 main-title">Primary color</h2>
          </Col>
          <Col lg="12">
            <div className="options-container">
              {primaryColors.map((color, index) => (
                <div
                  className="option"
                  style={{ backgroundColor: color }}
                  onClick={() => changePrimary(index)}
                >
                  {primaryColor === index && (
                    <div className="check">
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </Col>

          {/* third */}
          <Col lg="12">
            <h2 className="mt-5 main-title">Font size</h2>
          </Col>
          <Col lg="12">
            <div className="options-container">
              {fontSizes.map((size, index) => (
                <button className="btn" onClick={() => changeFontSize(index)}>
                  {size.title}
                  {fontSize === index && (
                    <span>
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                  )}
                </button>
              ))}
            </div>
          </Col>
          {/* fourth */}
          <Col lg="12">
            <h2 className="mt-5 main-title">Animation speed</h2>
          </Col>
          <Col lg="12">
            <div className="options-container">
              {animationSpeeds.map((speed, index) => (
                <button className="btn" onClick={() => changeSpeed(index)}>
                  {speed.title}
                  {animationSpeed === index && (
                    <span>
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                  )}
                </button>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Settings;
